import React from 'react'
import "../src/foodbank-css/style.css";
import { Link } from 'react-router-dom'

export default function nav() {
  return (
    <>
        <div className="foodbank-navbar">
            <img src="foodbank-images/foodbank-logo2.png" alt="" className="fn-logo" />
            <Link to={'https://company.foodbank-app.com.ng/'} className='fn-btn'>
                <p>
                  Company Sign Up
                </p>
            </Link>
        </div>
    </>
  )
}

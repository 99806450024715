/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import manImage from "./foodbank-images/man-image.png";
import backIcon from "../src/foodbank-images/back-icon.png";
import NavBar from "./Navbar";
import Sidebar from "./sideBar";
import { Link } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { useUser } from "./UserContext";

export default function TransactionChain3() {
  const navigate = useNavigate();
  const location = useLocation();
  const { userInfo } = useUser();

  const totalItemsPrice = location.state && location.state.totalItemsPrice;
  const orderId = location.state && location.state.orderId;
  const numberOfItems = location.state && location.state.numberOfItems;
  const userFullname = location.state && location.state.userFullname;
  const deliveryDetails = location.state && location.state.deliveryDetails;
  const formattedOrderDate =
    location.state && location.state.formattedOrderDate;
  const sampleSummary_ = location.state && location.state.sampleSummary_;

  const [orderDetails, setOrderDetails] = useState("");

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/order/singleOrder/${orderId}`
        );

        if (response.status === 200) {
          setOrderDetails(response.data);
        } else {
          toast.error("Failed to fetch order details ", {
            style: {
              border: "1px solid #D2042D",
              padding: "16px",
              color: "#D2042D",
            },
            iconTheme: {
              primary: "#D2042D",
              secondary: "#FFFAEE",
            },
          });
        }
      } catch (error) {
        toast.error("Error fetching order details ", {
          style: {
            border: "1px solid #D2042D",
            padding: "16px",
            color: "#D2042D",
          },
          iconTheme: {
            primary: "#D2042D",
            secondary: "#FFFAEE",
          },
        });
      }
    };

    fetchOrderDetails();
  }, [orderId]);

  return (
    <div className="dashboard">
      <NavBar />
      <div className="dashboard-space">
        <Sidebar />
        <div className="major-container">
          <span
            className="back-btn"
            style={{
              background: "white",
              boxShadow: "0 2px 5px rgb(222, 222, 222)",
            }}
          >
            <img
              src={backIcon}
              alt="back"
              onClick={() => {
                navigate(-1);
              }}
            />
          </span>
          <div className="chain-flow">
            <span className="greenbg-color"></span>
            <hr className="green-color" />
            <span className="greenbg-color"></span>
            <hr className="green-color" />
            <span className="greenbg-color"></span>
          </div>
          <div className="chain-container">
            <section className="cc-order-description">
              <div>
                <p>
                  Order No - <span>{orderDetails.orderNumber}</span>
                </p>
                <time>{formattedOrderDate}</time>
              </div>
              <div>{orderDetails.status}</div>
            </section>
            <section className="item-sale-desc">
              <p>{numberOfItems}Items Purchased</p>
              <span>Show items</span>
            </section>
            <hr className="cc-order-line" />
            <div className="cc-buyer-info">
              <section className="cc-role">
                <img src={userInfo.userImage} alt="user" />
                <div>
                  <p className="csr-role">{userFullname}</p>
                  {/* <p className='csr-client'>Buyer</p> */}
                </div>
              </section>
              <p className="order-price">&#8358; {totalItemsPrice}</p>
            </div>
            <div className="cc-delivery-detail">
              <div className="cc-dd-subheading">
                <p>Delivery address</p>
                <Link href="#">Edit</Link>
              </div>
              <section>
                <span>
                  <p className="dda-detail">State</p>
                  <p className="dda-data">{deliveryDetails.state}</p>
                </span>
                <span>
                  <p className="dda-detail">City</p>
                  <p className="dda-data"> {deliveryDetails.city}</p>
                </span>
                <span>
                  <p className="dda-detail">Location</p>
                  <p className="dda-data">{deliveryDetails.location}</p>
                </span>
                <span>
                  <p className="dda-detail">Phone number</p>
                  <p className="dda-data">{deliveryDetails.phoneNumber}</p>
                </span>
              </section>
            </div>
            <div className="cc-payment-detail">
              <div className="cc-dd-subheading">
                <p>Payment Summary</p>
              </div>
              <section>
                {sampleSummary_.map((item, index) => (
                  <span key={index}>
                    <p className="psd-detail">{item.value}</p>
                    <p className="psd-price">&#8358; {item.price}</p>
                  </span>
                ))}
                <hr className="ccp-line" />
                <div className="payment-total-prc">
                  <p>Total Price</p>
                  <p>&#8358; {orderDetails.allItemsTotalPrice}</p>
                </div>
              </section>
              <Link to="/Dashboard" className="reg-form-btn">
                Back to Dashboard
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

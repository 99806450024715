import React from "react";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";

const Back = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1); 
  }

  return (
    <div
      onClick={goBack}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#D3D3D3',
        height: '50px',
        width: '50px',
        borderRadius: '50%',
        cursor: 'pointer', 
      }}
    >
      <IoIosArrowBack style={{ color: 'black', width: '70px'}} />
    </div>
  );
};

export default Back;

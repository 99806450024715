/* eslint-disable no-unused-vars */
import React from "react";
import "../src/foodbank-css/dashboard.css";
import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "./sideBar";
import NavBar from "./Navbar";
import modalCircle from "../src/foodbank-images/info-circle.png";
import axios from "axios";
import toast from "react-hot-toast";
// import { useUser } from './UserContext';

export default function Dashboard() {
  // const location = useLocation();
  const navigate = useNavigate();
  const isApproved = sessionStorage.getItem("isApproved");
  // const userToken = location.state && location.state.token;
  const userId = sessionStorage.getItem("userId");
  const userToken = sessionStorage.getItem("userToken");

  const [confirmModal, setconfirmModal] = useState(isApproved === "false");
  const [shoppingHistory, setShoppingHistory] = useState([]);
  const [sideBarShow, setSideBarShow] = useState(false);

  useEffect(() => {
    const fetchShoppingHistory = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/order/getShoppingHistory/${userId}`
        );
        setShoppingHistory(response.data);
      } catch (error) {
        toast.error('Error fetching shopping history', {
          style: {
            border: '1px solid #D2042D',
            padding: '16px',
            color: '#D2042D',
          },
          iconTheme: {
            primary: '#D2042D',
            secondary: '#FFFAEE',
          },
        });
      }
    };

    fetchShoppingHistory();
  }, [userId]);

  const [walletData, setWalletData] = useState([]);
  const [walletTotalData, setWalletTotalData] = useState([]);
  const [walletCurrentLoanData, setWalletCurrentLoanData] = useState([]);
  const [monthlyPayback, setMonthlyPayback] = useState([]);
  const [amountLeft, setAmountLeft] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/wallet/getWallet/${userId}`
        );
        const { totalLoan, paidLoan, currentLoan,montlyPayBack } = response.data;

                const walletData = parseFloat(paidLoan).toFixed(2);
                const walletTotalData = parseFloat(totalLoan).toFixed(2);
                const walletCurrentLoanData = parseFloat(currentLoan).toFixed(2);
                const monthlyPayback = parseFloat((montlyPayBack).toFixed(2));
                const amountLeft = parseFloat((currentLoan - paidLoan).toFixed(2));

                setWalletData(walletData);
                setWalletTotalData(walletTotalData);
                setWalletCurrentLoanData(walletCurrentLoanData);
                setMonthlyPayback(monthlyPayback);
                setAmountLeft(amountLeft)
      } catch (error) {
        // toast('Error fetching wallet data');
      }
    };

    fetchData();
  }, [userId]);

  const applyForLoan = () => {
    navigate("/Store");
  };

  return (
    <div className="dashboard">
      <NavBar setSideBarShow={setSideBarShow} />
      <div className="dashboard-space">
        <Sidebar sideBarShow={sideBarShow} setSideBarShow={setSideBarShow} />
        <div className="major-container">
          <header className="home-header">
            <div className="home-loan-application hl-div">
              <p>Monthly Payback</p>
              <h1 style={{fontSize: '35px'}}>&#8358; {monthlyPayback.toLocaleString('en-US')}</h1>
              <span onClick={applyForLoan}>Apply for food loan</span>
            </div>
            <div className="home-loan-statement hl-div">
              <section className="hls-balance1">
                <div className="current-loan">
                  <p>Current loan</p>
                  <h2>-&#8358; {parseFloat(walletCurrentLoanData).toLocaleString('en-US')}</h2>
                </div>
                <div className="paid-loan">
                  <p>Paid loan</p>
                  <h2>&#8358; {parseFloat(walletData).toLocaleString('en-US')}</h2>
                </div>
              </section>
              <section className="hls-balance2">
                <div className="Amount-left">
                  <p>Amount left</p>
                  <h2>&#8358; {amountLeft.toLocaleString('en-US')}</h2>
                </div>
                <div className="Total-loan">
                  <p>Total loan</p>
                  <h2>&#8358; {parseFloat(walletTotalData).toLocaleString('en-US')}</h2>
                </div>
              </section>
            </div>
          </header>
          <main className="home-history-space">
            <h2 className="history-subheading">Order history</h2>
            <div className="home-table-space">
              <table>
                <thead>
                  <th>S/n</th>
                  <th>Order no</th>
                  <th>Time</th>
                  <th>No of items</th>
                  <th>Amount</th>
                </thead>
                <tbody>
                {shoppingHistory.map((historyItem, index) => (
                  <tr key={historyItem.orderNumber}>
                    <td>{index + 1}</td>
                    {/* <td>
                                                    <span className='table-span'>
                                                        <img src={manImageTable} alt='' className='table-img' />
                                                        <small>{historyItem.userFullname}</small>
                                                    </span>
                                                </td> */}
                    <td>{historyItem.orderNumber}</td>
                    <td>{new Date(historyItem.orderDate).toDateString()}</td>
                    <td>{historyItem.orderItems.length} {historyItem.orderItems.length > 1 ? ' Items' : ' Item'}</td>
                    <td>&#8358; {historyItem.allItemsTotalPrice.toLocaleString('en-US')}</td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </main>
        </div>
      </div>
      {confirmModal ? (
        <div
          className="verification-modal-bg"
          onClick={() => {
            setconfirmModal(true);
          }}
        >
          <div className="otp-verification-modal">
            <img src={modalCircle} alt="" className="otp-check-pic" />
            <h2>Identity Confirmation</h2>
            <p className="confirm-text">
              Kindly wait as we verify your identity with your workplace. You'll
              receive an email once your eligibility is confirmed.
            </p>
            <Link to={"/login"}>Continue</Link>
          </div>
        </div>
      ) : null}
    </div>
  );
}

import WOW from 'wowjs';
import { useState, useEffect } from "react";


const FaqsAccordion =  ({ title, content }) => {
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
      const wow = new WOW.WOW();
      wow.init();
    },[]);

    const toggleAccordion = () => {
      setIsOpen(!isOpen);
    };
  
    return (
      <div style={{margin: '15px 0', border: '0.5px solid green', boxShadow: '0 2px 5px rgb(222, 222, 222)', borderRadius: '8px'}}>
        <div  style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between',  padding: '5px 20px', borderRadius: '10px', fontSize: '14px' }} onClick={toggleAccordion}>
          <h3 style={{fontSize: '15px', fontWeight: 'normal'}}> {title}</h3>
          <span style={{cursor: 'pointer', fontSize: '23px', color: 'green'}}>{isOpen ? '-' : '+'}</span>
        </div>
        {isOpen && <div  className={`wow ${isOpen ? 'animate__fadeInUp' : ''}`}
          data-wow-duration="5s" style={{boxShadow: '0 2px 5px rgb(222, 222, 222)',  padding: '15px 20px',  fontSize: '14px', borderTop: '1px solid grey'}}>{content}</div>}
      </div>
    );
}

export default FaqsAccordion;
import React from 'react';
import Phonebg from './Phonebg_space';
import { useState } from 'react';
import Logo from '../src/foodbank-images/foodbank-logo.png';
import Eye from '../src/foodbank-images/eye.png';
import CheckImage from '../src/foodbank-images/check-img.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';

export default function ResetPdChange() {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [resetPd, setresetPd] = useState(false);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const userId = location.state && location.state.userId;

    const handleContinue = async () => {
        try {
            setLoading(true);
            await new Promise(resolve => setTimeout(resolve, 1000));
            if (newPassword !== confirmPassword) {
                toast('Passwords do not match!', {
                    icon: '⚠️',
                  });
                return;
            }

            const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/v1/users/change/password/${userId}`, {
                password: newPassword
            });

            if (response.data) {
                setLoading(false);
                setresetPd(true);
                navigate('/login')
            }
        } catch (e) {
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    return (
        <main className="reg-main">
            <div className="reg-form-space">
                <img src={Logo} alt="" className="reg-logo" />
                <h2 className="registration-subheading">
                    Reset Password
                </h2>
                <p className="regristration-descriptive-text">
                    {/* We've sent a code to <span>{userId}</span> */}
                </p>
                <form action="" className="registration-form">
                    <section className='reg-setup-section'>
                        <label htmlFor="">
                            New Password
                        </label>
                        <div className="reg-password-space">
                            <input
                                type="password"
                                placeholder='Choose a password'
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                            <figure className='reg-eye-icon'>
                                <img src={Eye} alt="" />
                            </figure>
                        </div>
                    </section>
                    <p className="password-criteria">
                        Password must contain one symbol and one character
                    </p>
                    <section className='reg-setup-section'>
                        <label htmlFor="">
                            Confirm-password
                        </label>
                        <div className="reg-password-space">
                            <input
                                type="password"
                                placeholder='Re-enter your password'
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                            <figure className='reg-eye-icon'>
                                <img src={Eye} alt="" />
                            </figure>
                        </div>
                    </section>
                    {loading && (
                        <div className="preloader-overlay">
                            <div className="preloader"></div>
                        </div>
                    )}
                    <div type='submit' className='reg-form-btn' onClick={handleContinue} disabled={loading}>Reset password</div>
                </form>
            </div>
            <Phonebg></Phonebg>
            {resetPd ? <div className="verification-modal-bg" onClick={() => { setresetPd(false) }}>
                <div className="otp-verification-modal">
                    <img src={CheckImage} alt="" className="otp-check-pic" />
                    <h2>
                        Password changed
                    </h2>
                    <p>
                        Success! Your password has been updated.
                    </p>
                    <Link to="#" onClick={() => { setresetPd(false) }}>
                        Continue
                    </Link>
                </div>
            </div> : null}
        </main>
    )
}

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

const PaymentConfirm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const userId = sessionStorage.getItem('userId');

   // const urlSearchParams = new URLSearchParams(window.location.search);
  // const params = Object.fromEntries(urlSearchParams.entries());
   // const userEmail = sessionStorage.getItem('userEmail');

  // Check if userId is present in the URL
  // if (params.userId) {
  //     const userId = params.userId;
  //     console.log(userId)

  // Now you can make the API call to update the user's payment status
  // using the endpoint: `${process.env.REACT_APP_API_BASE_URL}/api/v1/users/make-payment/${userId}`

  useEffect(() => {
    const makePayment = async () => {
      try {
        setLoading(true);
        await new Promise(resolve => setTimeout(resolve, 1000));

        const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/users/make-payment/${userId}`;
        const apiResponse = await axios.put(apiUrl);

        if (apiResponse.status === 200) {
          toast.success('Payment was successfully processed!', {
            style: {
              border: '1px solid #50C878',
              padding: '16px',
              color: '#50C878',
            },
            iconTheme: {
              primary: '#50C878',
              secondary: '#FFFAEE',
            },
          });
          setLoading(false);
          navigate('/Login');
        } else {
          toast.error('Payment was not successfully processed.', {
            style: {
              border: '1px solid #D2042D',
              padding: '16px',
              color: '#D2042D',
            },
            iconTheme: {
              primary: '#D2042D',
              secondary: '#FFFAEE',
            },
          });
          navigate('/Payment');
        }
      } catch (error) {
        setLoading(false);
        navigate('/Login');
      } finally {
        setLoading(false);
      }
    };

    makePayment();
  }, [navigate, userId]);

  return (
    <div>
      {loading && (
        <div className="preloader-overlay">
          <div className="preloader"></div>
        </div>
      )}
    </div>
  );
}

export default PaymentConfirm;

// @ts-nocheck
import WOW from "wowjs";
import axios from "axios";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import Nav from "./Nav";
import Footer from "./components.jsx/Footer";
import homeImage from "./foodbank-images/home_foodbank.jpg";
import { Link } from "react-router-dom";
import { useTypewriter } from "react-simple-typewriter";
import FaqsAccordion from "./FaqsAccordion";

export default function Landingpage() {
  const [faqData, setFaqData] = useState([]);

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();

    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/admin/getFaqs`)
      .then((response) => {
        const faqItems = response.data.faqs;
        setFaqData(faqItems);
      })
      .catch((error) => {
        toast.error("Error fetching FAQs ", {
          style: {
            border: "1px solid #D2042D",
            padding: "16px",
            color: "#D2042D",
          },
          iconTheme: {
            primary: "#D2042D",
            secondary: "#FFFAEE",
          },
        });
      });
  }, []);
  const [text] = useTypewriter({
    words: ["Get food items from foodbank at a flexible payment plan"],
  });
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Nav />
      <header className="lp-header">
        <h1 className="header-subheading">{text}</h1>
        <p className="header-text" data-aos="fade-up">
          The Goal is to Make Life Easy, Let The employee Breath !!
        </p>
        <div className="lp-header-links" data-aos="fade-up">
          <Link to={"/Login"}>
            <p>Sign in</p>
          </Link>
          <Link to={"/Reg1"}>
            <p>Get started</p>
          </Link>
        </div>
      </header>
      <img
        alt="foodbank"
        src={homeImage}
        className="wow animate__fadeInUp after-header-image"
        data-wow-duration="3s"
      />

      <div
        className="wow animate__fadeInUp second-subheading-space"
        data-wow-duration="3s"
      >
        <h2 className="second-subheading">
          Get food items from foodbank at affordable prices
        </h2>
        <p className="header-text">
          We want to help the employee live a well planned life and make them
          enjoy their job on the go!!
        </p>
      </div>
      <main className="lp-grid-container">
        <section className="wow animate__fadeInUp " data-wow-duration="3s">
          <h2>Select food items</h2>
          <p>Take your pick from our delectable food selection.</p>
        </section>
        <section className="wow animate__fadeInLeft" data-wow-duration="3s">
          <h2>Wait for your order to be approved</h2>
          <p>Just Relax and we will notify you</p>
        </section>
        <section className="wow animate__fadeInRight" data-wow-duration="3s">
          <h2>Receive your order.</h2>
          <p>Your eagerly awaited package is on its way to you!</p>
        </section>
      </main>
      <section
        className="wow animate__fadeInUp"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <h1 className="second-subheading" style={{ fontSize: "20px" }}>
          Frequently Asked Questions
        </h1>
        <div className="faqs profile-second-tab">
          {faqData.map((faqsItem, index) => (
            <FaqsAccordion
              key={index}
              title={faqsItem.title}
              content={faqsItem.content}
            />
          ))}
        </div>
      </section>

      <div
        className="wow animate__fadeInUp last-content-space"
        data-wow-duration="3s"
      >
        <div className="lcs-circle"></div>
        <h2>Join Us Today!!</h2>
        <p className="lcs-text">
          Finalize your purchase by securely submitting your Request.
        </p>
        <Link to={"/Reg1"}>
          <p>Get Started</p>
        </Link>
      </div>
      <Footer />
    </>
  );
}

import { Box, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, } from "@chakra-ui/react";

const DeleteModal = ({
  isOpen,
  onClose,
  itemId,
  onDelete, 
}) => {
    const handleDelete = () => {
        onDelete();
      };

  return (
    <Box key={itemId}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay bg="rgba(0, 0, 0, 0.5)" p={{ base: 4, md: "unset" }} />
        <ModalContent>
          <ModalHeader>Confirm Deletion</ModalHeader>
          <ModalBody>Are you sure you want to delete this food item?</ModalBody>
          <ModalFooter>
            <button
              onClick={handleDelete}
              type="button"
              style={{borderRadius: '8px', padding: '0.5rem', color: 'white', background: 'red', marginLeft: '0.5rem', fontWeight: 'bold'}}
              >
               Delete
            </button>

            <button onClick={onClose} style={{ marginLeft: '0.5rem'}}>
              Cancel
            </button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default DeleteModal;
import React, { useState } from 'react';
import Phonebg from './Phonebg_space';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

export default function Forgotpd() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');

    const handleContinue = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            await new Promise(resolve => setTimeout(resolve, 100));

            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/user/send-verification-code`, { email });

            if (response.status === 200) {
                setLoading(false);
                toast.success('OTP has been sent to your email', {
                    style: {
                      border: '1px solid #50C878',
                      padding: '16px',
                      color: '#50C878',
                    },
                    iconTheme: {
                      primary: '#50C878',
                      secondary: '#FFFAEE',
                    },
                  });
                navigate('/Resetpd', {state:{ email }});
            }
        } catch (error) {
            toast.error('Error sending verification code', {
                style: {
                  border: '1px solid #D2042D',
                  padding: '16px',
                  color: '#D2042D',
                },
                iconTheme: {
                  primary: '#D2042D',
                  secondary: '#FFFAEE',
                },
              });
        }
    };

    return (
        <main className="reg-main">
            <div className="reg-form-space">
                <img src="foodbank-images/foodbank-logo.png" alt="" className="reg-logo" />
                <h2 className="registration-subheading">
                    Forgot Password?
                </h2>
                <p className="regristration-descriptive-text">
                    No worries, it happens to the best of us. <br /> We'll help you reset your password.
                </p>
                <form onSubmit={handleContinue} className="registration-form">
                    <section className='reg-setup-section'>
                        <label htmlFor="">
                            Email
                        </label>
                        <input
                            type="email"
                            placeholder='Enter your correct email address'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </section>
                    {loading && (
                        <div className="preloader-overlay">
                            <div className="preloader"></div>
                        </div>
                    )}
                    <button type='submit' className='reg-form-btn' disabled={loading}>Continue</button>
                </form>
            </div>
            <Phonebg></Phonebg>
        </main>
    )
}

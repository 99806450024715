import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer className='foodbank-footer'>
        <img src="foodbank-images/foodbank-logo2.png" alt="" className="footer-logo" />
        <div className="ff-links">
            <Link to={'https://sovereigntechltd.com/about.html'}>About</Link>
            <Link to={'https://sovereigntechltd.com/contact.html'}>Contact Us</Link>
            <Link to={'https://sovereigntechltd.com/services.html'}>Services</Link>
        </div>
    </footer>
  )
}

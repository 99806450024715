import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
// import manImageTable from '../src/foodbank-images/man-image.png';
import backIcon from "../src/foodbank-images/back-icon.png";
// import { useUser } from './UserContext';
import NavBar from "./Navbar";
import Sidebar from "./sideBar";
import toast from "react-hot-toast";
import { useUser } from "./UserContext";

export default function History1() {
  const [sideBarShow, setSideBarShow] = useState(false);
  const [shoppingHistory, setShoppingHistory] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const navigate = useNavigate();
  // const { userInfo } = useUser();
  const userId = sessionStorage.getItem("userId");
  const userEmail = sessionStorage.getItem("userEmail");
  const { userInfo } = useUser();

  const userToken = sessionStorage.getItem('userToken');

  useEffect(() => {
    const fetchShoppingHistory = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/order/getShoppingHistory/${userId}`
        );
        setShoppingHistory(response.data);
      } catch (error) {
        toast.error("Error fetching shopping history", {
          style: {
            border: "1px solid #D2042D",
            padding: "16px",
            color: "#D2042D",
          },
          iconTheme: {
            primary: "#D2042D",
            secondary: "#FFFAEE",
          },
        });
      }
    };

    fetchShoppingHistory();
  }, [userId]);

  const handleDeliveryTotalAmount = (deliveryFee, serviceFee) => {
    const sum = deliveryFee + serviceFee;
    setTotalAmount(sum);
  };

  const config = {
    public_key: process.env.REACT_APP_FLUTTERWAVE_KEY,
    tx_ref: Date.now(),
    amount: +totalAmount,
    currency: "NGN",
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: userEmail,
      name: userInfo.fullName,
    },
    customizations: {
      title: "  Food Bank Loan",
      description: "Delivery fees for the current orders ",
      logo: "https://admin.sovereigntechltd.com/assets/img/logo.png",
    },
  };

  const handleFlutterPayment = useFlutterwave(config);

  const handleProceed = (id) => {
    axios
      .put(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/order/confirmOrderPayment/${id}`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res)
      })
      .catch((err) => console.log(err));
  };


  return (
    <>
      <div className="dashboard">
        <NavBar setSideBarShow={setSideBarShow} />
        <div className="dashboard-space">
          <Sidebar sideBarShow={sideBarShow} setSideBarShow={setSideBarShow} />
          <div className="major-container">
            <span
              className="back-btn"
              style={{
                background: "white",
                boxShadow: "0 2px 5px rgb(222, 222, 222)",
              }}
            >
              <img
                src={backIcon}
                alt="back"
                onClick={() => {
                  navigate(-1);
                }}
              />
            </span>
            <main className="home-history-space">
              <h2 className="history-subheading">Recent Orders</h2>
              <div className="home-table-space">
                <table>
                  <thead>
                    <tr>
                      <th>S/n</th>
                      <th>Order no</th>
                      <th>Time</th>
                      <th>No of items</th>
                      <th>Amount</th>
                      <th>Delivery</th>
                    </tr>
                  </thead>
                  <tbody>
                    {shoppingHistory.map((historyItem, index) => (
                      <tr key={historyItem.orderNumber}>
                        <td>{index + 1}</td>
                        {/* <td>
                                                    <span className='table-span'>
                                                        <img src={manImageTable} alt='' className='table-img' />
                                                        <small>{historyItem.userFullname}</small>
                                                    </span>
                                                </td> */}
                        <td>{historyItem.orderNumber}</td>
                        <td>
                          {new Date(historyItem.orderDate).toDateString()}
                        </td>
                        <td>{historyItem.orderItems.length}{historyItem.orderItems.length > 1 ? ' Items' : ' Item'} </td>
                        <td>
                          &#8358;{" "}
                          {historyItem.allItemsTotalPrice.toLocaleString(
                            "en-US"
                          )}
                        </td>
                        <td>
                          {historyItem.isPaymentDS ? <button disabled className="reg-form-btnn" style={{background: 'white', color: 'green'}} >Paid</button> : 
                          <button
                            onClick={() => {
                              handleDeliveryTotalAmount(
                                historyItem.deliveryFee,
                                historyItem.serviceFee
                              );
                              handleFlutterPayment({
                                callback: (response) => {
                                  handleProceed(historyItem._id);
                                  toast.success(response, {
                                    style: {
                                      border: "1px solid #50C878",
                                      padding: "16px",
                                      color: "#50C878",
                                    },
                                    iconTheme: {
                                      primary: "#50C878",
                                      secondary: "#FFFAEE",
                                    },
                                  });
                                  closePaymentModal();
                                },
                                onClose: () => {
                                  toast.error(
                                    "Delivery payment is not successful",
                                    {
                                      style: {
                                        border: "1px solid #D2042D",
                                        padding: "16px",
                                        color: "#D2042D",
                                      },
                                      iconTheme: {
                                        primary: "#D2042D",
                                        secondary: "#FFFAEE",
                                      },
                                    }
                                  );
                                },
                              });
                            }}
                            style={{ textAlign: "center" }}
                            className="reg-form-btnn"
                          >
                            Pay
                          </button>}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
}

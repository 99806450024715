/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Sidebar from "./sideBar";
import NavBar from "./Navbar";
import backIcon from "../src/foodbank-images/back-icon.png";
import cartMockupImage from "../src/foodbank-images/cart-mockup.png";
import toast from "react-hot-toast";
import DeleteModal from "./DeleteModal";
import { useUser } from "./UserContext";
import { CiSearch } from "react-icons/ci";
// import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";

export default function Store() {
  const navigate = useNavigate();
  const userId = sessionStorage.getItem("userId");
  const [inputValue, setInputValue] = useState("");
  const [sideBarShow, setSideBarShow] = useState(false);
  const { tabOne, tabTwo, removeTabOne, removeTabTwo } = useUser();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [itemToDeleteId, setItemToDeleteId] = useState(null);
  const [category, setCategory] = useState([]);
  const [ProductCategory, setProductCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [availableItems, setavailableItems] = useState(true);
  const [allAvailableItems, SetAllAvailableItems] = useState([]);
  const [selectedInput, setSelectedInput] = useState([]);
  const [availableItem, setavailableItem] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [itemAddedToCart, setItemAddedToCart] = useState(false);

  const [userEmail, setUserEmail] = useState(null);
  const [userName, setUserName] = useState(null);
  const [userPhoneNo, setUserPhoneNo] = useState(null);

  useEffect(() => {
    const fetchUserEmail = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/users/${userId}`
        );
        setUserEmail(response.data.email);
        setUserName(response.data.fullname);
        setUserPhoneNo(response.data.phonenumber);
      } catch (error) {
        console.error("Error getting user email:", error);
      }
    };

    fetchUserEmail();
    getAllProductTypeHandler();
  }, [userId]);

  const getAllProductTypeHandler = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/admin/getProductCategory`
      )
      .then((res) => setCategory(res.data))
      .catch((err) =>
        toast.error("Unable to fetch products, Please try again later  ", {
          style: {
            border: "1px solid #D2042D",
            padding: "16px",
            color: "#D2042D",
          },
          iconTheme: {
            primary: "#D2042D",
            secondary: "#FFFAEE",
          },
        })
      );
  };

  const getProductByCategoryHandler = async (type) => {
    const value = type.toLowerCase();
    await axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/product/getProducts/${value}`
      )
      .then((res) => {
        setProductCategory(res.data);
        setSelectedCategory(type);
      })
      .catch((err) =>
        toast.error("Unable to fetch products, Please try again later ", {
          style: {
            border: "1px solid #D2042D",
            padding: "16px",
            color: "#D2042D",
          },
          iconTheme: {
            primary: "#D2042D",
            secondary: "#FFFAEE",
          },
        })
      );
  };

  const searchCategoryHandler = async () => {
    const value = inputValue.toLowerCase();
    await axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/admin/searchProductCategory/${value}`
      )
      .then((res) => {
        setSelectedInput(res.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          toast.error("No category found! ", {
            style: {
              border: "1px solid #D2042D",
              padding: "10px",
              color: "#D2042D",
            },
            iconTheme: {
              primary: "#D2042D",
              secondary: "#FFFAEE",
            },
          });
        } else {
          toast.error("Unable to get category! ", {
            style: {
              border: "1px solid #D2042D",
              padding: "10px",
              color: "#D2042D",
            },
            iconTheme: {
              primary: "#D2042D",
              secondary: "#FFFAEE",
            },
          });
        }
        setInputValue("");
      });
  };

  const fetchProductDetails = async (productId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/product/${productId}`
      );
      setSelectedProduct(response.data);
    } catch (error) {
      toast.error("Error fetching product details ", {
        style: {
          border: "1px solid #D2042D",
          padding: "16px",
          color: "#D2042D",
        },
        iconTheme: {
          primary: "#D2042D",
          secondary: "#FFFAEE",
        },
      });
    }
  };

  function selectedItem(productId) {
    setavailableItem(true);
    setavailableItems(false);
    fetchProductDetails(productId);
  }

  function backAvail() {
    setavailableItems(true);
    setavailableItem(false);
  }

  const [IqValue, setIqValue] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);
  const addVal = () => {
    setIqValue((prevValue) => prevValue + 1);
  };
  const subVal = () => {
    setIqValue((prevValue) => Math.max(1, prevValue - 1));
  };

  useEffect(() => {
    if (selectedProduct) {
      setTotalPrice(selectedProduct.price * IqValue);
    }
  }, [IqValue, selectedProduct]);

  const [addToCartModal, setaddToCartModal] = useState(false);

  const fetchAvailableItems = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/product`
      );
      SetAllAvailableItems(response.data);
    } catch (error) {
      toast.error("Error fetching available items", {
        style: {
          border: "1px solid #D2042D",
          padding: "16px",
          color: "#D2042D",
        },
        iconTheme: {
          primary: "#D2042D",
          secondary: "#FFFAEE",
        },
      });
    }
  };

  useEffect(() => {
    fetchAvailableItems();
  }, []);

  const handleAddToCart = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/cart/addToCart`,
        {
          userId: userId,
          name: selectedProduct.name,
          image: selectedProduct.image,
          measurement: selectedProduct.measurement,
          type: selectedProduct.type,
          totalPrice: totalPrice,
          quantity: IqValue,
        }
      );

      if (response.status === 200) {
        setItemAddedToCart(true);
        setaddToCartModal(true);
      } else {
        toast.error("Error adding to cart", {
          style: {
            border: "1px solid #D2042D",
            padding: "16px",
            color: "#D2042D",
          },
          iconTheme: {
            primary: "#D2042D",
            secondary: "#FFFAEE",
          },
        });
      }
    } catch (error) {
      toast.error("Error adding to cart", {
        style: {
          border: "1px solid #D2042D",
          padding: "16px",
          color: "#D2042D",
        },
        iconTheme: {
          primary: "#D2042D",
          secondary: "#FFFAEE",
        },
      });
    }
  };
  const [cartItems, setCartItems] = useState([]);
  const fetchCartItems = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/cart/getCartItems`,
        {
          params: {
            userId: userId,
          },
        }
      );

      if (response.status === 200) {
        setCartItems(response.data.items);
      } else if (response.status === 404) {
        toast("Cart is empty!", {
          icon: "🤥",
        });
      }
    } catch (error) {
      toast.error("Error fetching cart items", {
        style: {
          border: "1px solid #D2042D",
          padding: "16px",
          color: "#D2042D",
        },
        iconTheme: {
          primary: "#D2042D",
          secondary: "#FFFAEE",
        },
      });
    }
  };

  useEffect(() => {
    fetchCartItems();
  }, [tabTwo]);

  const [latestPaymentSummary, setLatestPaymentSummary] = useState(null);

  const fetchLatestPaymentSummary = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/admin/getPaymentChargesSummary`
      );

      const sortedData = response.data.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );

      // Select the first item (latest) from the sorted data
      const latestItem = sortedData[0];

      const latestValues = {
        serviceFee: latestItem.serviceFee,
        vat: latestItem.vat,
        deliveryFee: latestItem.deliveryFee,
        interest: latestItem.interest,
      };

      setLatestPaymentSummary(latestValues);
    } catch (error) {
      toast.error("API request failed", {
        style: {
          border: "1px solid #D2042D",
          padding: "16px",
          color: "#D2042D",
        },
        iconTheme: {
          primary: "#D2042D",
          secondary: "#FFFAEE",
        },
      });
    }
  };

  useEffect(() => {
    fetchLatestPaymentSummary();
  }, []);

  const totalItemsPrice = cartItems.reduce(
    (total, cartItem) => total + cartItem.totalPrice,
    0
  );

  const sampleSummary = latestPaymentSummary
    ? [
        { value: "Total items price", price: ` ${totalItemsPrice}` },
        { value: "Service fee", price: `${latestPaymentSummary.serviceFee}` },
        {
          value: "VAT  (7.5%)",
          price: `${
            (latestPaymentSummary.vat / 100) * totalItemsPrice.toFixed(2)
          }`,
        },
        {
          value: "Interest (5%)",
          price: `${(latestPaymentSummary.interest / 100) * totalItemsPrice}`,
        },
        { value: "Delivery fee", price: `${latestPaymentSummary.deliveryFee}` },
      ]
    : [];
  // const overallTotal = sampleSummary.reduce((total, item) => {
  //   const price = parseFloat(item.price.replace(/[^0-9.]/g, "")); // Remove non-numeric characters
  //   return total + price;
  // }, 0);
  const overallTotal = sampleSummary.reduce((total, item) => {
    const price = parseFloat(item.price.replace(/[^0-9.]/g, "")); // Remove non-numeric characters
    return total + price;
  }, 0);
  const overallTotalWithDelivery = sampleSummary.reduce((total, item) => {
    const price = parseFloat(item.price.replace(/[^0-9.]/g, "")); // Remove non-numeric characters
    return total + price;
  }, 0);

  // Check if latestPaymentSummary is truthy before accessing its properties
  const overallTotal2 = latestPaymentSummary
    ? overallTotalWithDelivery - latestPaymentSummary.deliveryFee
    : 0;
  const deliveryFee = latestPaymentSummary
    ? latestPaymentSummary.deliveryFee
    : 0;

  const serviceFee = latestPaymentSummary ? latestPaymentSummary.serviceFee : 0;

  // const sampleSummary_ = latestPaymentSummary
  //   ? [
  //       { value: "Service fee", price: `${latestPaymentSummary.serviceFee}` },
  //       { value: "VAT", price: `${latestPaymentSummary.vat}` },
  //       { value: "Interest", price: `${(3 / 100) * totalItemsPrice}` },
  //       { value: "Delivery fee", price: `${latestPaymentSummary.deliveryFee}` },
  //     ]
  //   : [];

  const handleProceed = () => {
    navigate("/TransactionChain1", {
      state: {
        userId,
        totalItemsPrice,
        overallTotal,
        sampleSummary,
        deliveryFee,
        serviceFee,
      },
    });
  };

  const deleteCartItem = async (itemId) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/cart/deleteCartItem/${userId}/${itemId}`
      );

      if (response.status === 200) {
        toast.success("Cart item deleted successfully", {
          style: {
            border: "1px solid #50C878",
            padding: "16px",
            color: "#50C878",
          },
          iconTheme: {
            primary: "#50C878",
            secondary: "#FFFAEE",
          },
        });
        fetchCartItems();
      } else if (response.status === 404) {
        toast.error("Cart item not found or cart not found ", {
          style: {
            border: "1px solid #D2042D",
            padding: "16px",
            color: "#D2042D",
          },
          iconTheme: {
            primary: "#D2042D",
            secondary: "#FFFAEE",
          },
        });
      }
    } catch (error) {
      toast.error("Error deleting cart item", {
        style: {
          border: "1px solid #D2042D",
          padding: "16px",
          color: "#D2042D",
        },
        iconTheme: {
          primary: "#D2042D",
          secondary: "#FFFAEE",
        },
      });
    }
    setIsDeleteModalOpen(false);
    setItemToDeleteId(null);
  };

  // const config = {
  //   public_key: process.env.REACT_APP_FLUTTERWAVE_KEY,
  //   tx_ref: Date.now(),
  //   amount: DeliveryFee,
  //   currency: "NGN",
  //   payment_options: "card,mobilemoney,ussd",
  //   customer: {
  //     email: userEmail,
  //     phone_number: userPhoneNo,
  //     name: userName,
  //   },
  //   customizations: {
  //     title: "Delivery Fee",
  //     description: "Delivery fees for the current orders ",
  //     logo: "https://admin.sovereigntechltd.com/assets/img/logo.png",
  //   },
  // };

  // const handleFlutterPayment = useFlutterwave(config);

  return (
    <div className="dashboard">
      <NavBar setSideBarShow={setSideBarShow} />
      <div className="dashboard-space">
        <Sidebar sideBarShow={sideBarShow} setSideBarShow={setSideBarShow} />
        <div className="major-container">
          <header className="mj-heading">
            <div className="mj-heading-space">
              <DeleteModal
                isOpen={isDeleteModalOpen}
                onClose={() => {
                  setIsDeleteModalOpen(false);
                  setItemToDeleteId(null);
                }}
                onDelete={() => {
                  deleteCartItem(itemToDeleteId);
                  setIsDeleteModalOpen(false);
                }}
              />
              <span
                className="back-btn"
                style={{
                  background: "white",
                  boxShadow: "0 2px 5px rgb(222, 222, 222)",
                }}
                onClick={() => {
                  window.location.reload(false);
                }}
              >
                <img src={backIcon} alt="back" />
              </span>
              <span
                className={`mjh-btn mjhb1 ${
                  tabOne ? "activate-btn" : "inactive-btn"
                }`}
                onClick={removeTabTwo}
              >
                Available items
              </span>
              <span
                className={`mjh-btn mjhb2 ${
                  tabTwo ? "activate-btn" : "inactive-btn"
                }`}
                onClick={removeTabOne}
              >
                My cart
              </span>
            </div>
            {!selectedCategory && !tabTwo && (
              <div className="reg-password-space search-category-wrapper">
                <input
                  type="search"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  className="searchcategory"
                  placeholder="search for a category"
                />
                <CiSearch
                  className="searchcategory-icon"
                  style={{ cursor: "pointer" }}
                  onClick={searchCategoryHandler}
                />
              </div>
            )}
          </header>
          {tabOne && !availableItem && (
            <section className="available-items-tab">
              {selectedCategory ? (
                <>
                  <div className="available-items-space">
                    {ProductCategory.map((product) => (
                      <div
                        className="available-item"
                        key={product._id}
                        onClick={() => selectedItem(product._id)}
                      >
                        <div className="available-item-img">
                          <p
                            style={{
                              background: "#ccf2e2",
                              borderRadius: "10px",
                              padding: "5px 10px",
                              fontSize: "12px",
                              marginLeft: "6rem",
                            }}
                          >
                            {product.measurement}
                          </p>
                          <img
                            src={product.image}
                            alt={product.name}
                            style={{
                              width: "70px",
                              height: "70px",
                              textAlign: "center",
                            }}
                          />
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                            width: "100%",
                            background: "#f5f5f5",
                            padding: "5px",
                            borderBottomRightRadius: "8px",
                            borderBottomLeftRadius: "8px",
                          }}
                        >
                          <p style={{ fontSize: "14px", paddingBottom: "5px" }}>
                            {product.name}
                          </p>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <p
                              style={{
                                background: "white",
                                color: "#008B50",
                                borderRadius: "10px",
                                padding: "5px 10px",
                                fontSize: "12px",
                              }}
                            >
                              &#8358;{product.price.toLocaleString("en-US")}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <>
                  <main className="landingpage-main">
                    {inputValue.length === 0 &&
                      category.map((product) => (
                        <div
                          className="landingpage-div"
                          key={product.type}
                          onClick={() =>
                            getProductByCategoryHandler(product.type)
                          }
                          style={{ backgroundImage: `url(${product.image})`, objectFit: 'contain'}}
                        >
                          <a className="main-links" href="#" style={{textTransform:'capitalize'}}>
                            {product.type}
                          </a>
                        </div>
                      ))}
                    {selectedInput.map((product) => (
                      <div
                        className="landingpage-div"
                        key={product.type}
                        onClick={() =>
                          getProductByCategoryHandler(product.type)
                        }
                        style={{ backgroundImage: `url(${product.image})` }}
                      >
                        <a className="main-links" href="#" style={{textTransform:'capitalize'}}>
                          {product.type}
                        </a>
                      </div>
                    ))}
                  </main>
                </>
              )}
            </section>
          )}
          {!tabTwo && availableItem ? (
            <div className="selected-item-space">
              <section className="sis-item-view">
                <div className="main-view-space">
                  {selectedProduct && (
                    <img
                      src={selectedProduct.image}
                      alt={selectedProduct.name}
                    />
                  )}
                </div>
                {/* <div className="item-images">
                      {Array.from({ length: 4 }).map((_, index) => (
                        <span key={index}>
                          {selectedProduct && (
                            <img
                              src={selectedProduct.image}
                              alt={selectedProduct.name}
                            />
                          )}
                        </span>
                      ))}
                    </div> */}
              </section>
              <section className="sis-item-detail">
                {selectedProduct && <h2>{selectedProduct.name}</h2>}

                {selectedProduct && (
                  <p className="texxt">{selectedProduct.description}</p>
                )}
                <div className="sis-detail-items">
                  {selectedProduct && (
                    <span>{selectedProduct.measurement}</span>
                  )}
                </div>
                <div className="item-quantity">
                  <div className="iq-size">
                    <span className="iq-sub-btn" onClick={subVal}>
                      -
                    </span>
                    <p className="iq-value">{IqValue}</p>
                    <span className="iq-add-btn" onClick={addVal}>
                      +
                    </span>
                  </div>
                  <div className="item-amount">
                    <p>Total price of Item</p>
                    {selectedProduct && (
                      <p>&#8358; {totalPrice.toLocaleString("en-US")}</p>
                    )}
                  </div>
                </div>
                <div className="reg-form-btn" onClick={handleAddToCart}>
                  <img src="foodbank-images/cart-bag-icon.png" alt="" />
                  <p>Add to cart</p>
                </div>
              </section>
            </div>
          ) : null}
          {tabTwo && (
            <section className="my-cart-tab">
              {cartItems.length > 0 ? (
                <section className="my-cart-tab">
                  <div className="my-cart-space">
                    <section className="mcs-1">
                      <p className="mcs-subheading">Today</p>
                      <div className="order-items">
                        {cartItems.map((item) => (
                          <section key={item._id} className="order-item">
                            <div className="oi-side1">
                              <figure>
                                <img src={item.image} alt={item.name} />
                              </figure>
                              <div className="oi-side1-details">
                                <p>{item.name}</p>
                                <p>{item.quantity}</p>
                                <p>
                                  &#8358;{" "}
                                  {item.totalPrice.toLocaleString("en-US")}
                                </p>
                              </div>
                            </div>
                            <div
                              className="oi-side2"
                              onClick={() => {
                                setIsDeleteModalOpen(true);
                                setItemToDeleteId(item._id);
                              }}
                            >
                              <img src="foodbank-images/trash.png" alt="" />
                            </div>
                          </section>
                        ))}
                      </div>
                    </section>
                    <section className="mcs-2">
                      <p className="mcs-subheading">Payment Summary</p>
                      <div className="order-payment-summary">
                        <section className="payment-summary-description">
                          {sampleSummary.map((item, index) => (
                            <span key={index}>
                              <p className="psd-detail">{item.value}</p>
                              <p className="psd-price">
                                &#8358; {parseFloat(item.price).toFixed(2)}
                              </p>
                            </span>
                          ))}
                          <hr className="psd-hr" style={{ minWidth: "70%" }} />
                          <span className="psd-total">
                            <p className="psd-detail">Overall Total </p>
                            <p className="psd-price">
                              &#8358; {overallTotal.toLocaleString("en-US")}
                            </p>
                          </span>
                        </section>
                      </div>
                    </section>
                  </div>
                  <button onClick={handleProceed} className="reg-form-btn">
                    Proceed
                  </button>
                </section>
              ) : (
                <section className="empty-history-space">
                  <img src={cartMockupImage} alt="" />
                  <h3>No items in the cart</h3>
                  <a href="/Store">Shop Now</a>
                </section>
              )}
            </section>
          )}
        </div>
      </div>
      {addToCartModal ? (
        <div
          className="verification-modal-bg"
          onClick={() => {
            setaddToCartModal(false);
          }}
        >
          <div className="otp-verification-modal">
            <img
              src="foodbank-images/check-img.png"
              alt=""
              className="otp-check-pic"
            />
            <h2>Item Added</h2>
            <p>Success! Your order is now in your cart, ready for checkout.</p>
            <a href="#" onClick={removeTabOne}>
              Go to cart
            </a>
          </div>
        </div>
      ) : null}
    </div>
  );
}

import React from 'react'
import Iphone from '../src/foodbank-images/iPhone 14 Pro.png'

export default function Phonebg_space() {
  return (
    <figure className="side-bg-registration">
        <img src={Iphone} alt="" />
    </figure>
  )
}

import React, { useState } from 'react'
import toast from 'react-hot-toast';
import Phonebg from './Phonebg_space';
import { Link, useNavigate } from 'react-router-dom';
import './App.css'
import axios from 'axios';
import { useUser } from './UserContext';
import Back from './utils/Back';
import { GoEyeClosed, GoEye } from "react-icons/go";


export default function Login() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);


  const { updateUserInfo } = useUser();
  const [fullName, setFullName] = useState('');
  const [userImage, setUserImage] = useState('');
  const [isApproved, setIsApproved] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!email || !password) {
      toast('Please enter both email and password!', {
        icon: '⚠️',
      });
      return;
    }
  
    try {
      setLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 10));
  
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/users/login`,
        {
          email: email,
          password: password,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
  
      const data = response.data;
      const userId = data.id;
        const userEmail = data.user;
        const userToken = data.token;
  
      sessionStorage.setItem('userToken', data.token);
      sessionStorage.setItem('userId', data.id);
      sessionStorage.setItem('userEmail', data.user);
      sessionStorage.setItem('isApproved', data.approve);
  
      if (response.status === 200 && data.member === true) {
        const userId = data.id;
        const userEmail = data.user;
        const userToken = data.token;
  
        if (data.approve === true && data.payment === true) {
          try {
            const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/users/${userId}`;
  
            const userResponse = await axios.get(apiUrl, {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            });
  
            if (
              userResponse.data &&
              userResponse.data.fullname &&
              userResponse.data.isApproved
            ) {
              setLoading(false);
              setFullName(userResponse.data.fullname);
              setUserImage(userResponse.data.image);
              const isApproved = userResponse.data.isApproved;
              sessionStorage.setItem('isApproved', isApproved);
              updateUserInfo({
                fullName: userResponse.data.fullname,
                userImage: userResponse.data.image,
              });
              navigate('/Dashboard');
            } else {
              toast.error('Failed to fetch user details', {
                style: {
                  border: '1px solid #D2042D',
                  padding: '16px',
                  color: '#D2042D',
                },
                iconTheme: {
                  primary: '#D2042D',
                  secondary: '#FFFAEE',
                },
              });
            }
          } catch (error) {
            // console.log(error);
            
          }
        } else if (data.approve === true && data.payment === false) {
          setLoading(false);
          navigate('/Payment', { state: { userId, userEmail } });
        } else {
          setLoading(false);
          navigate('/Dashboard');
        }
      } else if (data.member === false) {
        setLoading(false);
        navigate('/RegOtp',{state: {userId:userId,userEmail:userEmail}});
      } else {
        if (response.status === 400) {
          toast.error('Invalid email or password', {
            style: {
              border: '1px solid #D2042D',
              padding: '16px',
              color: '#D2042D',
            },
            iconTheme: {
              primary: '#D2042D',
              secondary: '#FFFAEE',
            },
          });
          setLoading(false);
        } else {
          toast.error(`Login failed: ${response.statusText}`, {
            style: {
              border: '1px solid #D2042D',
              padding: '16px',
              color: '#D2042D',
            },
            iconTheme: {
              primary: '#D2042D',
              secondary: '#FFFAEE',
            },
          });
          setLoading(false);
        }
      }
    } catch (error) {
      toast.error(error.response.data, {
        style: {
          border: '1px solid #D2042D',
          padding: '16px',
          color: '#D2042D',
        },
        iconTheme: {
          primary: '#D2042D',
          secondary: '#FFFAEE',
        },
      });
      setLoading(false);
    }
  };
  
  return (
    <main className="reg-main">
      <div className="reg-form-space">
      <div className='backButton'>
          <Back />
        </div>
        <img src="foodbank-images/foodbank-logo.png" alt="" className="reg-logo" />
        <h2 className="registration-subheading">
          Welcome back
        </h2>
        <p className="regristration-descriptive-text" style={{width: '80%'}}>
          Great to see you again!  Your journey continues here.
        </p>
        <form onSubmit={handleSubmit} className="registration-form">

          <section className='reg-setup-section'>
            <label htmlFor="">
              Email
            </label>
            <input
              type="email"
              id="email"
              placeholder='Enter your correct email address'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </section>
          <section className='reg-setup-section'>
            <label htmlFor="">
              Password
            </label>
            <div className="reg-password-space">
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                placeholder='Choose a password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {/* <figure className='reg-eye-icon'>
                <img src="foodbank-images/eye.png" alt="" />
              </figure> */}
              <figure  onClick={togglePasswordVisibility} style={{ cursor: 'pointer'}}>
             {showPassword ? <GoEye /> : <GoEyeClosed />}
          </figure>
            </div>
          </section>
          <Link to="/Forgotpd" className='reg-forgot-pd'>
            Forgot password?
          </Link>
          {loading && (
            <div className="preloader-overlay">
              <div className="preloader"></div>
            </div>
          )}
          <button type='submit' className='reg-form-btn' disabled={loading}>
            Continue
          </button>
          <span className='reg-form-sign-space'>
            <p>Don't have an account? </p>
            <Link to="/Reg1">Create account</Link>
          </span>
        </form>
      </div>
      <Phonebg></Phonebg>
    </main>
  )
}

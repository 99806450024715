import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Phonebg from "./Phonebg_space";
import axios from "axios";
import "../src/foodbank-css/Registration.css";
import "./App.css";
import toast from "react-hot-toast";
import Back from "./utils/Back";
import { GoEyeClosed, GoEye } from "react-icons/go";


export default function Reg1() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setConfirmPassword((prevPassword) => !prevPassword);
  };

  const parameter = {
    fullname: formData.firstName + " " + formData.lastName,
    email: formData.email,
    password: formData.password,
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const isPasswordValid = () => {
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/;
    return passwordRegex.test(formData.password);
  };

  const isEmailValid = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(formData.email);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      toast.error("Password and Confirm Password do not match", {
        style: {
          border: "1px solid #D2042D",
          padding: "16px",
          color: "#D2042D",
        },
        iconTheme: {
          primary: "#D2042D",
          secondary: "#FFFAEE",
        },
      });
      return;
    }

    if (!isEmailValid()) {
      toast.error("Please enter a valid email address", {
        style: {
          border: "1px solid #D2042D",
          padding: "16px",
          color: "#D2042D",
        },
        iconTheme: {
          primary: "#D2042D",
          secondary: "#FFFAEE",
        },
      });
      return;
    }

    if (!isPasswordValid()) {
      toast.error("Password must be at least 8 characters and contain both letters and numbers", {
        style: {
          border: "1px solid #D2042D",
          padding: "16px",
          color: "#D2042D",
        },
        iconTheme: {
          primary: "#D2042D",
          secondary: "#FFFAEE",
        },
      });
      return;
    }
  
    try {
      setLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 1000));
  
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/users`,
        parameter,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.status === 200) {
        const data = response.data;
        const userId = data.id;
        navigate("/RegOtp", {
          state: { userEmail: formData.email, userId: userId },
        });
      } else {
        toast.error("Failed to register user", {
          style: {
            border: "1px solid #D2042D",
            padding: "16px",
            color: "#D2042D",
          },
          iconTheme: {
            primary: "#D2042D",
            secondary: "#FFFAEE",
          },
        });
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        style: {
          border: "1px solid #D2042D",
          padding: "16px",
          color: "#D2042D",
        },
        iconTheme: {
          primary: "#D2042D",
          secondary: "#FFFAEE",
        },
      });
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <main className="reg-main">
      <div className="reg-form-space"> 
      <div className='backButton'>
          <Back />
        </div>
        <img
          src="foodbank-images/foodbank-logo.png"
          alt=""
          className="reg-logo"
        />
        <h2 className="registration-subheading">Set up your profile</h2>
        <p className="regristration-descriptive-text" style={{width: '80%'}}>
          Personalize your experience by creating your profile today.
        </p>
        <form onSubmit={handleSubmit} className="registration-form">
          <section className="reg-setup-section">
            <label htmlFor="firstName">First name</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              required
              placeholder="Enter your first name"
              value={formData.firstName}
              onChange={handleChange}
            />
          </section>
          <section className="reg-setup-section">
            <label htmlFor="lastName">Last name</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              required
              placeholder="Enter your last name"
              value={formData.lastName}
              onChange={handleChange}
            />
          </section>
          <section className="reg-setup-section">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              required
              placeholder="Enter your correct email address"
              value={formData.email}
              onChange={handleChange}
            />
          </section>
          <section className="reg-setup-section">
            <label htmlFor="password">Password</label>
            <div className="reg-password-space">
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                name="password"
                required
                placeholder="Choose a password"
                value={formData.password}
                onChange={handleChange}
              />
              <figure  onClick={togglePasswordVisibility} style={{ cursor: 'pointer'}}>
             {showPassword ? <GoEye /> : <GoEyeClosed />}
          </figure>
            </div>
          </section>
          <section className="reg-setup-section">
            <label htmlFor="confirmPassword">Confirm-password</label>
            <div className="reg-password-space">
              <input
                type={confirmPassword ? 'text' : 'password'}
                id="confirmPassword"
                name="confirmPassword"
                required
                placeholder="Re-enter your password"
                value={formData.confirmPassword}
                onChange={handleChange}
              />
              <figure  onClick={toggleConfirmPasswordVisibility} style={{ cursor: 'pointer'}}>
             {confirmPassword ? <GoEye /> : <GoEyeClosed />}
          </figure>
            </div>
          </section>
          {/* Overlay with loading spinner when loading is true */}
          {loading && (
            <div className="preloader-overlay">
              <div className="preloader"></div>
            </div>
          )}
          <button type="submit" className="reg-form-btn" disabled={loading}>
            Continue
          </button>
          <span className="reg-form-sign-space">
            <p>Already have an account? </p>
            <Link to="/Login">Sign in</Link>
          </span>
        </form>
      </div>
      <Phonebg></Phonebg>
    </main>
  );
}

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { FlutterWaveButton, closePaymentModal } from 'flutterwave-react-v3';
import toast from 'react-hot-toast';

export default function Payment2() {
    const location = useLocation();
    const userEmail = location.state && location.state.Email;
    const [flutterwavePublicKey, setFlutterwavePublicKey] = useState(null);

    useEffect(() => {
        const fetchFlutterwavePublicKey = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/admin/flutterwave-public-key`);
                setFlutterwavePublicKey(response.data.publicKey);
            } catch (error) {
                toast.error('Error fetching Flutterwave public key', {
                    style: {
                      border: '1px solid #D2042D',
                      padding: '16px',
                      color: '#D2042D',
                    },
                    iconTheme: {
                      primary: '#D2042D',
                      secondary: '#FFFAEE',
                    },
                  });
            }
        };

        fetchFlutterwavePublicKey();
    }, []);


    const config = {
        public_key: flutterwavePublicKey,
        tx_ref: Date.now(),
        amount: 1000,
        currency: 'NGN',
        payment_options: 'card,mobilemoney,ussd',
        customer: {
            email: userEmail,
            phone_number: '070888889',
            name: 'john doe',
        },
        customizations: {
            title: 'Food Bank',
            description: 'Payment for registration',
            logo: 'https://admin.sovereigntechltd.com/assets/img/logo.png',
        },
    };

    const fwConfig = {
        ...config,
        text: 'Pay with Flutterwave!',
        callback: (response) => {
            // console.log(response);
            closePaymentModal()
        },
        onClose: () => {},
    };

    return (
        <div className="App">
            <FlutterWaveButton {...fwConfig} />
        </div>
    );
}
/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const PaymentScreen = () => {
  const location = useLocation();
  const userId = location.state && location.state.userId;

  useEffect(() => {
    // Redirect to Flutterwave payment link
    window.location.href = `https://flutterwave.com/pay/eubpabnupukb?userId=${userId}`;
  }, []); // Use an empty dependency array to run this effect only once on mount

  return null;
};

export default PaymentScreen;

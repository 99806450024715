import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { Button } from 'react-bootstrap';
// import hamburgerIcon from '../src/foodbank-images/foodbank-hamburger-icon.png';
// import manImage from '../src/foodbank-images/man-image.png';
// import property34 from '../src/foodbank-images/Property 34.png';
// import bag2 from '../src/foodbank-images/bag-2.png';
// import foodbankLogo2 from '../src/foodbank-images/foodbank-logo2.png';
// import homeInactive from '../src/foodbank-images/home-inactive.png';
// import storeInactive from '../src/foodbank-images/store-inactive.png';
// import historyInactive from '../src/foodbank-images/history-inactive.png';
// import profileActive from '../src/foodbank-images/profile-active.png';
import backIcon from "../src/foodbank-images/back-icon.png";
import frame from "../src/foodbank-images/frame.png";
import forwardIcon from "../src/foodbank-images/arrow-right.png";
import edit2 from "../src/foodbank-images/edit-2.png";
// import notificationIcon from '../src/foodbank-images/notification.png';
import securityUser from "../src/foodbank-images/security-user.png";
import messageQuestion from "../src/foodbank-images/message-question.png";
import headphones from "../src/foodbank-images/headphones.png";
import userPic from "../src/foodbank-images/user-pic.png";
import xCircle from "../src/foodbank-images/x-circle.png";
import checkImg from "../src/foodbank-images/check-img.png";
import NavBar from "./Navbar";
import Sidebar from "./sideBar";
import axios from "axios";
import { useUser } from "./UserContext";
import toast from "react-hot-toast";
import FaqsAccordion from "./FaqsAccordion";
import Terms from "./Terms";
import { CiEdit } from "react-icons/ci";


export default function Profile() {
  const { userInfo } = useUser();
  const userId = sessionStorage.getItem("userId");
  const [ChangeName, setChangeName] = useState(false);
  const [InfoChange, setInfoChange] = useState(false);
  const changeInfo = () => {
    setChangeName(false);
    setInfoChange(true);
  };
  const [sentMessage, setsentMessage] = useState(false);
  const [personalInfo, setpersonalInfo] = useState(true);
  const [notification, setnotification] = useState(false);
  const [TermsPrivacy, setTermsPrivacy] = useState(false);
  const [faqs, setfaqs] = useState(false);
  const [contactSupport, setcontactSupport] = useState(false);
  const navigate = useNavigate();

  const handlePersonalInfo = () => {
    setpersonalInfo(true);
    setnotification(false);
    setTermsPrivacy(false);
    setfaqs(false);
    setcontactSupport(false);
  };
//   const handleNotification = () => {
//     setpersonalInfo(false);
//     setnotification(true);
//     setTermsPrivacy(false);
//     setfaqs(false);
//     setcontactSupport(false);
//   };
  const handleTermsPrivacy = () => {
    setpersonalInfo(false);
    setnotification(false);
    setTermsPrivacy(true);
    setfaqs(false);
    setcontactSupport(false);
  };
  const handleFaq = () => {
    setpersonalInfo(false);
    setnotification(false);
    setTermsPrivacy(false);
    setfaqs(true);
    setcontactSupport(false);
  };
  const handleContactSupport = () => {
    setpersonalInfo(false);
    setnotification(false);
    setTermsPrivacy(false);
    setfaqs(false);
    setcontactSupport(true);
  };

  const [pdShow, setpdShow] = useState(false);
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/users/${userId}`)
      .then((response) => {
        setUserData(response.data);
      })
      .catch((error) => {
        toast.error("Error fetching user data.", {
          style: {
            border: "1px solid #D2042D",
            padding: "16px",
            color: "#D2042D",
          },
          iconTheme: {
            primary: "#D2042D",
            secondary: "#FFFAEE",
          },
        });
      });
  }, [userId]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [complaints, setComplaints] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      firstname: firstName,
      lastname: lastName,
      email: email,
      complaint: complaints,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/admin/createComplaint`,
        formData
      );
      if (response.status === 200) {
        setsentMessage(true);
      } else {
        toast.error("Error submitting form.", {
          style: {
            border: "1px solid #D2042D",
            padding: "16px",
            color: "#D2042D",
          },
          iconTheme: {
            primary: "#D2042D",
            secondary: "#FFFAEE",
          },
        });
      }
    } catch (error) {
      toast.error("Error submitting form: " + error.message, {
        style: {
          border: "1px solid #D2042D",
          padding: "16px",
          color: "#D2042D",
        },
        iconTheme: {
          primary: "#D2042D",
          secondary: "#FFFAEE",
        },
      });
    }
  };

  const [faqData, setFaqData] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/admin/getFaqs`)
      .then((response) => {
        const faqItems = response.data.faqs;
        setFaqData(faqItems);
      })
      .catch((error) => {
        toast.error("Error fetching FAQs ", {
          style: {
            border: "1px solid #D2042D",
            padding: "16px",
            color: "#D2042D",
          },
          iconTheme: {
            primary: "#D2042D",
            secondary: "#FFFAEE",
          },
        });
      });
  }, []);

  return (
    <div className="dashboard">
      <NavBar />
      <div className="dashboard-space">
        <Sidebar />
        <div className="major-container">
          <span className="back-btn" style={{ background: 'white', boxShadow: '0 2px 5px rgb(222, 222, 222)'}}>
            <img
              src={backIcon}
              alt="back"
              onClick={() => {
                setpdShow(false);
                navigate(-1)
              }}
            />
          </span>
          <main className="personal-info-space">
            <section
              className={`profile-description ${pdShow ? "showPd" : ""}`}
              onClick={() => setpdShow(!pdShow)}
            >
              <figure className="profile-picture">
                <img src={userInfo.userImage} alt="" />
              </figure>
              <div className="profile-nav-bar">
                <section className="pnb-item" onClick={handlePersonalInfo}>
                  <span>
                    <img src={frame} alt="" />
                    <p>Personal information</p>
                  </span>
                  <img src={forwardIcon} alt="" />
                </section>
                <section className="pnb-item" onClick={handleTermsPrivacy}>
                  <span>
                    <img src={securityUser} alt="" />
                    <p>Terms and privacy</p>
                  </span>
                  <img src={forwardIcon} alt="" />
                </section>
                <section className="pnb-item" onClick={handleFaq}>
                  <span>
                    <img src={messageQuestion} alt="" />
                    <p>FAQS</p>
                  </span>
                  <img src={forwardIcon} alt="" />
                </section>
                <section className="pnb-item" onClick={handleContactSupport}>
                  <span>
                    <img src={headphones} alt="" />
                    <p>Contact support</p>
                  </span>
                  <img src={forwardIcon} alt="" />
                </section>
              </div>
            </section>
            {personalInfo ? (
              <section className="personal-info-tab profile-second-tab">
                <div className="pi-item">
                  <span>
                    <p>Full name</p>
                    <p>{userData.fullname}</p>
                  </span>
                  <CiEdit style={{cursor: 'not-allowed', width: '40px', height: '20px', color: 'gray'}}/>
                  {/* <img
                    src={edit2}
                    // onClick={() => {
                    //   setChangeName(true);
                    // }}
                    alt=""
                  /> */}
                </div>
                <div className="pi-item">
                  <span>
                    <p>Email</p>
                    <p>{userData.email}</p>
                  </span>
                  <CiEdit style={{cursor: 'not-allowed', width: '40px', height: '20px', color: 'gray'}}/>
                </div>
                <div className="pi-item">
                  <span>
                    <p>Phone number</p>
                    <p>{userData.phonenumber}</p>
                  </span>
                  <CiEdit style={{cursor: 'not-allowed', width: '40px', height: '20px', color: 'gray'}}/>
                </div>
                <div className="pi-item">
                  <span>
                    <p>Home address</p>
                    <p>{userData.address}</p>
                  </span>
                  <CiEdit style={{cursor: 'not-allowed', width: '40px', height: '20px', color: 'gray'}}/>
                </div>
                <div className="pi-item">
                  <span>
                    <p>Company</p>
                    <p>{userData.company}</p>
                  </span>
                  <CiEdit style={{cursor: 'not-allowed', width: '40px', height: '20px', color: 'gray'}}/>
                </div>
                <div className="pi-item">
                  <span>
                    <p>Salary</p>
                    <p>&#8358; {userData.salary}</p>
                  </span>
                  <CiEdit style={{cursor: 'not-allowed', width: '40px', height: '20px', color: 'gray'}}/>
                </div>
                <div className="pi-item">
                  <span>
                    <p>Job title</p>
                    <p>{userData.jobtitle}</p>
                  </span>
                  <CiEdit style={{cursor: 'not-allowed', width: '40px', height: '20px', color: 'gray'}}/>
                </div>
              </section>
            ) : null}
            {notification ? (
              <section className="notification-tab profile-second-tab">
                <p className="notification-subheading">Today</p>
                <div className="notification-box">
                  <img src={userPic} alt="" />
                  <span>
                    <p>Your Order is on its way.</p>
                    <time>Just now</time>
                  </span>
                </div>
                <div className="notification-box">
                  <img src={userPic} alt="" />
                  <span>
                    <p>Your Order is on its way.</p>
                    <time>Just now</time>
                  </span>
                </div>
                <div className="notification-box">
                  <img src={userPic} alt="" />
                  <span>
                    <p>Your registration fee has been recieved!</p>
                    <time>Just now</time>
                  </span>
                </div>
                <div className="notification-box">
                  <img src={userPic} alt="" />
                  <span>
                    <p>Your Order is on its way.</p>
                    <time>Just now</time>
                  </span>
                </div>
                <div className="notification-box">
                  <img src={userPic} alt="" />
                  <span>
                    <p>Your Order is on its way.</p>
                    <time>Just now</time>
                  </span>
                </div>

                <p className="notification-subheading">23rd of October, 2023</p>

                <div className="notification-box">
                  <img src={userPic} alt="" />
                  <span>
                    <p>Your Order is on its way.</p>
                    <time>Just now</time>
                  </span>
                </div>
                <div className="notification-box">
                  <img src={userPic} alt="" />
                  <span>
                    <p>Your Order is on its way.</p>
                    <time>Just now</time>
                  </span>
                </div>
                <div className="notification-box">
                  <img src={userPic} alt="" />
                  <span>
                    <p>Your registration fee has been recieved!</p>
                    <time>Just now</time>
                  </span>
                </div>
                <div className="notification-box">
                  <img src={userPic} alt="" />
                  <span>
                    <p>Your Order is on its way.</p>
                    <time>Just now</time>
                  </span>
                </div>
                <div className="notification-box">
                  <img src={userPic} alt="" />
                  <span>
                    <p>Your Order is on its way.</p>
                    <time>Just now</time>
                  </span>
                </div>
              </section>
            ) : null}
            {TermsPrivacy ? (
              <Terms />
            ) : null}
            {faqs ? (
              <div className="faqs profile-second-tab" style={{ height: '500px', overflow: 'scroll'}}>
                {faqData.map((faqsItem, index) => ( 
                    <FaqsAccordion
                    key={index}
                  title={faqsItem.title}
                  content={faqsItem.content}
                />
                ))}
              </div>
            ) : null}
            {contactSupport ? (
              <section className="contact-us profile-second-tab" style={{ height: '500px', overflow: 'scroll'}}>
                <h2>
                  Fill the form to <span>Contact Us</span>
                </h2>
                <form className="registration-form" onSubmit={handleSubmit}>
                  <section className="reg-setup-section">
                    <label htmlFor="firstName">First name</label>
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      placeholder="Enter your first name"
                    />
                  </section>
                  <section className="reg-setup-section">
                    <label htmlFor="lastName">Last name</label>
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      placeholder="Enter your last name"
                    />
                  </section>
                  <section className="reg-setup-section">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter your correct email address"
                    />
                  </section>
                  <section className="reg-setup-section">
                    <label htmlFor="complaints">Your complaint</label>
                    <textarea
                      id="complaints"
                      name="complaints"
                      value={complaints}
                      onChange={(e) => setComplaints(e.target.value)}
                      cols="30"
                      rows="10"
                      placeholder="How can we help you?"
                    ></textarea>
                  </section>
                  <button type="submit" className="reg-form-btn">
                    Send Message
                  </button>
                </form>
              </section>
            ) : null}
          </main>
        </div>
      </div>
      {ChangeName ? (
        <div className="verification-modal-bg">
          <div className="otp-verification-modal">
            <figure className="reg-modal-cancelbtn">
              <img
                src={xCircle}
                onClick={() => {
                  setChangeName(false);
                }}
                alt=""
              />
            </figure>
            <form action="#" className="registration-form ">
              <section className="reg-setup-section">
                <label htmlFor="">Full name</label>
                <input type="text" htmlValue="Romel Lukaku" />
              </section>
              <button
                type="submit"
                className="reg-form-btn"
                onClick={changeInfo}
              >
                Save changes
              </button>
            </form>
          </div>
        </div>
      ) : null}
      {InfoChange ? (
        <div className="verification-modal-bg">
          <div className="otp-verification-modal">
            <img src={checkImg} alt="" className="otp-check-pic" />
            <h2>Information changed</h2>
            <p>
              Your order exceeds the cash limit. Please consider adjusting the
              items in your cart.
            </p>
            <a
              href="/#"
              onClick={() => {
                setInfoChange(false);
              }}
            >
              Back
            </a>
          </div>
        </div>
      ) : null}
      {sentMessage ? (
        <div className="verification-modal-bg">
          <div className="otp-verification-modal">
            <img src={checkImg} alt="" className="otp-check-pic" />
            <h2>Message Sent</h2>
            <p>
              Your order exceeds the cash limit. Please consider adjusting the
              items in your cart.
            </p>
            <a
              href="/Profile"
              onClick={() => {
                setsentMessage(false);
              }}
            >
              Back to Home
            </a>
          </div>
        </div>
      ) : null}
    </div>
  );
}

import React, { useState, useEffect } from "react";
import Phonebg from "./Phonebg_space";
import "../src/foodbank-css/Registration.css";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";

export default function Reg2() {
  const [register, setregister] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const userId = location.state && location.state.userId;
  const [formData, setFormData] = useState({
    phoneNumber: "",
    homeAddress: "",
    company: "",
    jobTitle: "",
    salary: "",
    staffId: "",
    image: null,
  });

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/admin/getAllCompany`
        );
        const data = await response.json();
        setCompanies(data);
      } catch (error) {
        toast.error("Error fetching companies", {
          style: {
            border: "1px solid #D2042D",
            padding: "16px",
            color: "#D2042D",
          },
          iconTheme: {
            primary: "#D2042D",
            secondary: "#FFFAEE",
          },
        });
      }
    };

    fetchCompanies();
  }, []);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      image: e.target.files[0],
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 1000));
  
    try {
      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/users/${userId}`;
  
      const data = new FormData();
      data.append("phonenumber", formData.phoneNumber);
      data.append("address", formData.homeAddress);
      data.append("company", selectedCompany);
      data.append("jobtitle", formData.jobTitle);
      data.append("salary", formData.salary);
      data.append("staffId", formData.staffId);
      data.append("image", formData.image);
  
      const response = await axios.put(apiUrl, data);
  
      if (response.status === 200) {
        toast.success("User data updated successfully", {
          style: {
            border: "1px solid #50C878",
            padding: "16px",
            color: "#50C878",
          },
          iconTheme: {
            primary: "#50C878",
            secondary: "#FFFAEE",
          },
        });
  
        const walletApiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/wallet/createWallet`;
        const walletData = {
          userId: userId,
        };
  
        const walletResponse = await axios.post(walletApiUrl, walletData);
  
        if (walletResponse.status === 200) {
          navigate("/Login");
          toast.success(
            "Proceed to make your own time payment, You can now login",
            {
              duration: 6000,
            },
            {
              style: {
                border: "1px solid #50C878",
                padding: "16px",
                color: "#50C878",
              },
              iconTheme: {
                primary: "#50C878",
                secondary: "#FFFAEE",
              },
            }
          );
        } else {
          toast.error("Wallet creation failed", {
            style: {
              border: "1px solid #D2042D",
              padding: "16px",
              color: "#D2042D",
            },
            iconTheme: {
              primary: "#D2042D",
              secondary: "#FFFAEE",
            },
          });
        }
      } else {
        const alldata = response.data;
        toast.error("Failed to update user data", alldata.message, {
          style: {
            border: "1px solid #D2042D",
            padding: "16px",
            color: "#D2042D",
          },
          iconTheme: {
            primary: "#D2042D",
            secondary: "#FFFAEE",
          },
        });
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        style: {
          border: "1px solid #D2042D",
          padding: "16px",
          color: "#D2042D",
        },
        iconTheme: {
          primary: "#D2042D",
          secondary: "#FFFAEE",
        },
      });
    } finally {
      setLoading(false);
    }
  };
  

  
  return (
    <main className="reg-main">
      <div className="reg-form-space">
        <img
          src="foodbank-images/foodbank-logo.png"
          alt=""
          className="reg-logo"
        />
        <h2 className="registration-subheading">Almost done!</h2>
        <p className="regristration-descriptive-text">
          You're just a step away from unlocking all the benefits!
        </p>
        <form className="registration-form" encType="multipart/form-data">
          <section className="reg-setup-section">
            <label htmlFor="">Phone number</label>
            <input
              type="text"
              placeholder="+234"
              name="phoneNumber"
              required
              value={formData.phoneNumber}
              onChange={handleInputChange}
            />
          </section>
          <section className="reg-setup-section">
            <label htmlFor="">Home address</label>
            <input
              type="text"
              placeholder="Enter your home address here"
              name="homeAddress"
              required
              value={formData.homeAddress}
              onChange={handleInputChange}
            />
          </section>
          <section className="reg-setup-section">
            <label htmlFor="">Company</label>
            {companies.length > 0 ? (
              <select
                name="company"
                id="company"
                required
                value={selectedCompany}
                onChange={(e) => setSelectedCompany(e.target.value)}
                className="select-btn"
              >
                <option value="" disabled>
                  Select a company
                </option>
                {companies.map((company) => (
                  <option key={company.id} value={company.companyName}>
                    {company.companyName}
                  </option>
                ))}
              </select>
            ) : (
              <p>Loading companies...</p>
            )}
          </section>
          <section className="reg-setup-section">
            <label htmlFor="">Job title</label>
            <input
              type="text"
              placeholder="Enter your job role here"
              name="jobTitle"
              required
              value={formData.jobTitle}
              onChange={handleInputChange}
            />
          </section>
          <section className="reg-setup-section">
            <label htmlFor="">Staff Id</label>
            <input
              type="text"
              placeholder="Enter your staff Id"
              name="staffId"
              required
              value={formData.staffId}
              onChange={handleInputChange}
            />
          </section>
          <section className="reg-setup-section">
            <label htmlFor="">Profile Image</label>
            <input
              type="file"
              name="image"
              required
              accept="image/*"
              onChange={handleFileChange}
            />
          </section>
          <section className="reg-setup-section">
            <label htmlFor="">Salary</label>
            <input
              type="text"
              placeholder="Enter your Salary here"
              name="salary"
              required
              value={formData.salary}
              onChange={handleInputChange}
            />
          </section>
          {loading && (
            <div className="preloader-overlay">
              <div className="preloader"></div>
            </div>
          )}
          <div
            className="reg-form-btn"
            onClick={handleSubmit}
            disabled={loading}
          >
            Continue
          </div>
        </form>
      </div>
      <Phonebg></Phonebg>
      {register ? (
        <div
          className="verification-modal-bg"
          onClick={() => {
            setregister(false);
          }}
        >
          <div className="otp-verification-modal">
            <img
              src="foodbank-images/check-img.png"
              alt=""
              className="otp-check-pic"
            />
            <h2>Registration completed</h2>
            <p>Success! Your registration has been completed.</p>
            <a
              href="#"
              onClick={() => {
                setregister(false);
              }}
            >
              Continue to dashboard
            </a>
          </div>
        </div>
      ) : null}
    </main>
  );
}

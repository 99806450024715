import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import logoImage from '../src/foodbank-images/foodbank-logo2.png';
import homeActiveImage from '../src/foodbank-images/home-active.png';
import homeInactiveImage from '../src/foodbank-images/home-inactive.png';
import storeInactiveImage from '../src/foodbank-images/store-inactive.png';
import storeActiveImage from '../src/foodbank-images/store-active.png';
import historyInactiveImage from '../src/foodbank-images/history-inactive.png';
import historyActiveImage from '../src/foodbank-images/history-active.png';
import profileInactiveImage from '../src/foodbank-images/profile-inactive.png';
import profileActiveImage from '../src/foodbank-images/profile-active.png';
import logout from './foodbank-images/logout.png'
import { useNavigate } from 'react-router-dom';

function Sidebar({ sideBarShow, setSideBarShow }) {
  const history = useNavigate();
  const location = useLocation(); 

  const handleLogout = () => {
    sessionStorage.removeItem('userToken');
    sessionStorage.removeItem('userId');
    history('/login'); 
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <div className={`dashboard-side-bar ${sideBarShow ? 'showsidebar' : ''}`} >
      <img src={logoImage} alt="" className="foodbank-sidebar-logo" />
      <div className="sidebar-links">
        <NavLink to="/Dashboard" className={`store-link ${isActive('/Dashboard') ? 'active active-link' : ''}`}>
          <img src={isActive('/Dashboard') ? homeActiveImage : homeInactiveImage} alt="home" />
          <p className={isActive('/Dashboard') ? 'dashboard-page-indication' : ''}>Home</p>
        </NavLink>
        <NavLink to="/Store" className={`store-link ${isActive('/Store') ? 'active active-link' : ''}`}>
          <img src={isActive('/Store') ? storeActiveImage : storeInactiveImage} alt="store" />
          <p className={isActive('/Store') ? 'dashboard-page-indication' : ''}>Store</p>
        </NavLink>
        <NavLink to="/History" className={`store-link ${isActive('/History') ? 'active active-link' : ''}`}>
          <img src={isActive('/History') ? historyActiveImage : historyInactiveImage} alt="history" />
          <p className={isActive('/History') ? 'dashboard-page-indication' : ''}>History</p>
        </NavLink>
        <NavLink to="/Profile" className={`store-link ${isActive('/Profile') ? 'active active-link' : ''}`}>
          <img src={isActive('/Profile') ? profileActiveImage : profileInactiveImage} alt="profile" />
          <p className={isActive('/Profile') ? 'dashboard-page-indication' : ''}>Profile</p>
        </NavLink>
        <NavLink onClick={handleLogout} className={`logout ${isActive('/Logout') ? 'active' : ''}`} >
          <img src={logout} alt="logout" />
          <p>Logout</p>
        </NavLink>
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  sideBarShow: PropTypes.bool.isRequired,
  setSideBarShow: PropTypes.func.isRequired,
};

export default Sidebar;
